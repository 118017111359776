import { useState } from 'react';
import { Map } from 'immutable';
import {
  Editor as Draft,
  EditorState,
  RichUtils,
  DraftBlockType,
  DraftBlockRenderConfig,
  ContentState,
} from 'draft-js';

import { Row, Text } from '@jl/assets';

import { Box, Rule } from './label.styles';
import { Button, ButtonProps } from './button';
import { useField } from './use-form';

export type EditorButton = (
  type: string,
) => Pick<ButtonProps, 'style' | 'onClick'>;

export type EditorProps = {
  name: string;
  readOnly?: boolean;
};

export const isContentState = (e: unknown): e is ContentState => !!e;

const renderMap = Map<DraftBlockType, DraftBlockRenderConfig>({
  unstyled: {
    element: Text,
  },
});

export const Editor = ({ name, readOnly }: EditorProps) => {
  const { value, setValue } = useField(name, {
    guard: isContentState,
    default: ContentState.createFromText(''),
  });
  const [state, setState] = useState(
    EditorState.createWithContent(value || ContentState.createFromText('')),
  );

  const markButton: EditorButton = (type) => ({
    style: state.getCurrentInlineStyle().has(type) ? 'highlight' : 'standard',
    onClick: () => setState(RichUtils.toggleInlineStyle(state, type)),
  });

  return (
    <Box>
      {!readOnly && (
        <Row>
          <Button icon="bold" size={15} {...markButton('BOLD')} />
          <Button icon="italic" size={15} {...markButton('ITALIC')} />
        </Row>
      )}
      <Draft
        editorState={state}
        onChange={(s) => {
          setState(s);
          setValue(s.getCurrentContent());
        }}
        blockRenderMap={renderMap}
        readOnly={readOnly}
        spellCheck
      />
      <Rule />
    </Box>
  );
};
